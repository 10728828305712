

/** Google font **/
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:500,600|Roboto:400,500');

// Color variables
$custom:                #13ce67;
$white:                 #ffffff;

$primary:               #1f2d4e;
$success:               #46cd93;
$info:                  #4bbbce;
$warning:               #fdba45;
$danger:                #f24734;
$dark:                  #0f1f3e;
$light:                 #f8f9fa;


$base-font: 14px;

$font-primary: "Roboto", sans-serif;
$font-secondary: "Libre Franklin", sans-serif;