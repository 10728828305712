

/* Theme Name: Zairo - Responsive bootsrap 4 Landing Page Template
   Author: Coderthemes
   File Description: Default template color
*/

.text-custom,
.btn-outline,
.open > .dropdown-toggle.btn-custom,
.btn-custom-white,
.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.focus,
.open > .dropdown-toggle.btn-outline-white,
.btn-custom-white:hover,
.btn-custom-white:focus,
.btn-custom-white:active,
.btn-custom-white.active,
.btn-custom-white.focus,
.open > .dropdown-toggle.btn-custom-white,
.navbar-btn,
.navbar-custom .navbar-nav li.active a,
.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:active,
.nav-sticky.navbar-custom .navbar-nav li.active a,
.nav-sticky.navbar-custom .navbar-nav li a:hover,
.nav-sticky.navbar-custom .navbar-nav li a:active,
.busi-team-icons li a:hover
{
    color: $custom;
}

.bg-custom,
.btn-custom,
.navbar-btn:hover,
.play-icon,
.container-filter li a:hover,
.container-filter li a.active,
.busi-container-filter li a:hover,
.busi-container-filter li a.active,
.footer-startup .footer-icons li a:hover,
.app-team-social-icon a:hover
{
	background: $custom;
}


.btn-custom,
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.open > .dropdown-toggle.btn-custom,
.container-filter li a:hover,
.container-filter li a.active,
.footer-startup .footer-icons li a:hover,
.busi-container-filter li a:hover,
.busi-container-filter li a.active,
.app-team-social-icon a:hover{
	border-color: $custom;
}

.app-border-right {
    border-right: 3px solid $custom;
}

.app-border-left {
    border-left: 3px solid $custom;
}

.arrow-right{
    border-left: 14px solid $custom;
}

.arrow-left{
    border-right: 14px solid $custom;
}

.startup-pricing-table-active {
    border: 2px solid rgba($custom, 0.4);
    border-top: 16px solid rgba($custom, 0.4);
}

.soft-team-content{
    border-bottom: 2px solid $custom;
}

.navbar-btn {
    background-color: rgba($custom, 0.2);
}

.feautures-icon i,
.app-testi-icon i,
.services-icon:after,
.busi-services-icon i{
    background: lighten($custom, 48%);
}

.testi-icon i{
    color: lighten($custom, 48%);
}

.btn-custom {
    border-bottom: 3px solid darken($custom, 5%);
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.open > .dropdown-toggle.btn-custom {
    background-color: darken($custom, 7%);
    border-color: darken($custom, 7%);
    border-bottom-color: darken($custom, 10%);
}

.spinner {
  border-left: 5px solid $custom;
}