
/*****************
Home Multipurpuse
*****************/


/********** Home ************/

.bg-home {
    position: relative;
    background-image: url("../images/bg-home.png");
    background-color: $primary;
    padding: 230px 0px 180px 0px;
    background-size: cover;
    background-position: center center;
}

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}

.home-title {
    h3{
        letter-spacing: 2px;
    }

    h5 {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 4px;
        font-weight: 700;
    }

    p, i, h5{
        color: #a0a8b9;
    }
}

.play-icon-circle {
    background: $white;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    color: $primary;
}


.mouse-down {
    .down-scroll {
        position: absolute;
        left: 0;
        right: 0;
        top: 155px;
        bottom: 0;
        z-index: 1;
        i{
            width: 48px;
            height: 48px;
            background: $white;
            display: inline-block;
            line-height: 48px;
            border-radius: 50%;
            box-shadow: 1px 5px 40px -15px $dark;
        }
    }
}

.video-button {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    font-size: 18px;
}

/*********** About *************/

.about-content {
    h4{
        line-height: 1.4;
        max-width: 590px;
    }
}

.typewriter-line {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        white-space: nowrap;
        font-size: 32px;
}

.about-headers-size {
    font-size: 28px;
}

.about-paragraphs-size {
    font-size: 18px;
}

/*********** Features ***********/

.feautures-icon {
    i{
        display: inline-block;
        width: 42px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 7px; 
    }
}

.feature-headers-size {
    font-size: 28px;
}

.feature-paragraphs-size {
    font-size: 18px;
}

/********** Applications ***********/

.applications-headers-size {
    font-size: 24px;
}

.applications-paragraphs-size {
    font-size: 14px;
}

/********** Services ***********/

.services-icon{
    &:after {
        content: "";
        position: absolute;
        top: 13px;
        left: 52px;
        width: 42px;
        height: 42px;
        margin: 0px auto;
        border-radius: 6px;
        z-index: -2;
    }
}

/********** Testimonial **********/

.carousel-indicators {
    align-items: center;
    li {
        width: 64px;
        height: 64px;
        margin-right: 5px;
        margin-left: 5px;
        opacity: 0.4;
        border-radius: 50%;
        border: 3px solid $white;
        box-shadow: 1px 9px 48px -15px $dark;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.5s;
    }
    .active{
        opacity: 1;
    }
}

.carousel-inner {
    min-height: 300px;
}

/********** Team ***********/

.team-box{
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 10px 50px rgba(20, 50, 47, 0.03), 0 10px 40px rgba(0, 0, 0, 0.03);
}

/********** Pricing **********/

.pricing-header {
    h4 {
        letter-spacing: 2px;
    }
}

.pricing-table {
    border-radius: 12px;
    border: 2px solid rgba($dark, 0.04)
}

/********** Clients ***********/

.client-images {
    img {
        max-height: 94px;
        width: auto !important;
        margin: 10px auto;
        opacity: 0.6;
        transition: all 0.5s;
        &:hover {
            opacity: 0.9;
        }
    }
}

/********* Responsive ***********/

@media (min-width: 200px) and (max-width: 768px) {
    .home-title {
        h3 {
            font-size: 26px;
        }
    }
    .mouse-down {
        .down-scroll {
            top: 99px;
        }
    }
    .pull-none{
        float: none !important;
        text-align: center;
    }
}

@media (max-width: 425px) {
    .watch-video{
        >a {
            span {
                display: none;
            }
        }
    }
    .carousel-inner{
        min-height: 385px;
    }
    .carousel-indicators {
        li{
            width: 50px;
            height: 50px;
        }
    }
    .carousel-indicators {
        .active{
            width: 70px;
            height: 70px;
        }
    }
}