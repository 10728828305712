
/********************
     Agency Layout
*********************/

.bg-home-agency{
    /* background: url("../images/bg-home-agency.jpg"); */
    background-size: cover;
    background-position: center center;
    height: 100vh;
    position: relative;
}

.agency-home-title{
    max-width: 780px;
}

.play-icon {
    height: 74px;
    width: 74px;
    line-height: 74px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    color: $white;
}

/******** agency-about ********/

.agency-about-img{
    position: relative;
    img{
        border-radius: 12px;
    }
}

.agency-about-img-desc{
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: rgba(0, 0, 0, 0.7);
}

/******** agency-services ********/

.agency-services-box{
    overflow: hidden;
}

.agency-services-icon {
    i {
        font-size: 36px;
        color: #6c757d;
    }    
}

/******** agency-work ********/

.container-filter {
    li {
        list-style: none;
        a {
            display: block;
            font-size: 12px;
            color: #6c757d !important;
            background: $light;
            border: 1px solid darken($light, 4%);
            padding: 17px 15px;
            margin: 18px 3px;
            text-transform: uppercase;
            letter-spacing: 2px;
            cursor: pointer;
            border-radius: 7px;
            -webkit-transition: all 0.6s;
            
        }
    }
}

.container-filter, .busi-container-filter{
    li{
        a{
            &:hover, &.active{
                color: $white !important;
                border: 1px solid;
            }
        }
    }
}

.item-box {
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 8px;
    a {
        display: inline-block;
    }
    &:hover {
        .item-mask {
            opacity: 1;
            visibility: visible;
            .item-caption {
                bottom: 50%;
                transform: translateY(50%);
                opacity: 1;
            }
        }
        .item-container {
            transform: scale(1.1);
            transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
            width: 100%;
        }
    }
}

.item-container {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    width: 100%;
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.item-mask {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
    position: absolute;
    transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    border-radius: 8px;
    p {
        font-size: 11px;
        letter-spacing: 1.5px;
        font-weight: 500;
        margin: 0;
    }
    .item-caption {
        position: absolute;
        bottom: -60px;
        left: 0px;
        right: 0px;
        padding-left: 30px;
        padding-right: 30px;
        transition: all 0.5s ease-in-out 0s;
        -moz-transition: all 0.5s ease-in-out 0s;
        -webkit-transition: all 0.5s ease-in-out 0s;
        -o-transition: all 0.5s ease-in-out 0s;
        opacity: 0;
    }
}

/******** agency-pricing ********/

.pricing-table-agency{
    box-shadow: 0 10px 50px rgba(20, 50, 47, 0.03), 0 10px 40px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
}

.pricing-title{
    overflow: hidden;
}
