

/* Theme Name: Zairo - Responsive bootsrap 4 Landing Page Template
   Author: Coderthemes
   Version: 1.0.0
   Created: Nov 2018
   File Description: Main Css file of the template
*/

/************************
01.General
02.Loader
03.Helper
04.Components
05.Menu
06.Footer
07.Multipurpose Layout
08.Contact
09.Agency Layout
10.Startup layout
11.Business Layout
12.Software Layout
12.App Layout
13.Default
************************/

@import "variables";
@import "general";
@import "loader";
@import "helper";
@import "components";
@import "menu";
@import "footer";
@import "multipurpose-layout";
@import "contact";
@import "agency-layout";
@import "startup-layout";
@import "business-layout";
@import "software-layout";
@import "app-layout";
@import "default";


.carousel-indicators li
{
    background-color: grey!important;
    height: 7px!important;
    width: 20px;
    border-radius: 3px; 
   margin-top: 100px;
}

#customCarousel .carousel-control-prev
{
    display: none;
}


#customCarousel .carousel-control-next
{
    display: none;
}
