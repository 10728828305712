

/******************
    Software Layout
*******************/

/******** software-home ********/

.bg-home-software {
    position: relative;
    background-image: url("../images/bg-software.png");
    background-color: $primary;
    padding: 230px 0px 180px 0px;
    background-size: cover;
    background-position: center center;
}

/******** software-features ********/

.bg-soft{
    background-image: url("../images/bg-software.png");
    background-color: $primary;
}

.soft-features-box {
    p {
        overflow: hidden;
    }
}

.soft-feautures-content {
    h4{
        line-height: 1.4;
    }
}

/******** software-team ********/

.soft-team-content{
    position: relative;
    bottom: 30px;
    box-shadow: 0 10px 50px rgba(20, 50, 47, 0.03), 0 10px 40px rgba(0, 0, 0, 0.03);
    border-bottom: 2px solid;
}

/******** software-testi ********/

.soft-testi-box{
    background-image: linear-gradient(45deg,rgba($white, 0.1) 25%,transparent 25%,transparent 50%,transparent 75%,transparent,transparent 75%,transparent);
    border: 1px solid rgba($white, 0.3);
    border-radius: 8px;
}

.soft-testi-icon {
    i{
        color: $white;
        background: rgba($white, 0.3);
        width: 56px;
        height: 56px;
        display: inline-block;
        text-align: center;
        line-height: 56px;
    }
}

.owl-theme {
    .owl-controls {
        .owl-page {
            span {
                width: 18px;
                height: 6px;
            }
        }
    }
}

/******** software-pricing ********/

.soft-pricing-price {
    h5{
        height: 94px;
        width: 94px;
        line-height: 94px;
        display: inline-block;
        border-radius: 50%;
    }
}

/********* Responsive ***********/

@media (min-width: 200px) and (max-width: 768px) {
    .bg-home,.bg-home-software{
        padding: 150px 0px 120px 0px;
    }
    .home-title h1,
    .startup-home-title h1,
    .soft-home-title h2,
    .agency-home-title h1,
    .busi-home-title h2 {
        font-size: 36px;
    }
}